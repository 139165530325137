import React from 'react';

const Background = () => {
    return (
        <div className="background-texture">
            <div className="grid-lines"></div>
            <div className="texture-mask"></div>
        </div>
    );
};

export default Background;