// components/ResetButton/ResetButton.js

import React from 'react';

function ResetButton({ reset }) {
  return (
    <button onClick={reset} className="reset-button">
      Reset Chat
    </button>
  );
}

export default ResetButton;
