import React from 'react';
import { trimInput } from "../../utils/utils";

function WordSuggestion({suggestions, onSelectWord}) {
  if (!suggestions || suggestions.length === 0) return null;

  return (
    <div className="suggestion-body">
      {/*<div className="suggestion-app">*/}
        {/*<div className="suggestion-window">*/}
          <div className="suggestion-content">
            <ul>
              {[...suggestions].reverse().map((suggestion, index) => {
                const trimmedWord = trimInput(suggestion.word);
                return (
                  <li key={index} onClick={() => onSelectWord(trimmedWord)}>
                    {trimmedWord}
                  </li>
                );
              })}
            </ul>
          </div>
        {/*</div>*/}
      {/*</div>*/}
    </div>
  );
}

export default WordSuggestion;
