import { useState, useCallback, useRef, useEffect } from 'react';
import { debounce } from 'lodash';
import { callAPI, getSuggestions } from "../utils/api";
import { setTitle, trimInput } from "../utils/utils";

function useChatLogic() {
  const [prompt, setPrompt] = useState('');
  const [conversation, setConversation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedWord, setSelectedWord] = useState('');

  const inputRef = useRef(null);
  const initialSearchDone = useRef(false);
  const isSubmitting = useRef(false);

  const updateUrl = useCallback((word) => {
    const newUrl = `${window.location.pathname}?w=${encodeURIComponent(word)}`;
    window.history.pushState({}, '', newUrl);
  }, []);

  const clearUrlParam = useCallback(() => {
    window.history.pushState({}, '', window.location.pathname);
  }, []);

  // const addMessage = useCallback((role, content) => {
  //   setConversation((prev) => [...prev, { role, content }]);
  // }, []);

  const addMessage = useCallback((role, content, isStreaming = false) => {
    setConversation(prev => {
      // If streaming and the last message is from assistant, update it
      if (isStreaming && prev.length > 0 && prev[prev.length - 1].role === 'assistant') {
        const newConv = [...prev];
        newConv[newConv.length - 1] = { role, content };
        return newConv;
      }
      // Otherwise add new message
      return [...prev, { role, content }];
    });
  }, []);

  const handleAPICall = useCallback(async (newPrompt) => {
    setIsLoading(true);
    let streamingMessage = '';

    try {
      await callAPI(
        [...conversation, { role: 'user', content: newPrompt }],
        (chunk) => {
          streamingMessage += chunk;
          addMessage('assistant', streamingMessage, true);
        }
      );
    } catch (error) {
      console.error('API call failed:', error);
      addMessage('assistant', `Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [conversation, addMessage]);

  const handleSubmit = useCallback((input) => {
    const trimmedInput = input.trim();
    if (isLoading || !trimmedInput) return;

    isSubmitting.current = true;
    setIsLoading(true);
    setSuggestions([]);
    setPrompt('');
    setSelectedWord('');

    addMessage('user', trimmedInput);
    handleAPICall(trimmedInput)
      .then(() => {
        if (conversation.length === 0) {
          setTitle(trimmedInput);
          updateUrl(trimmedInput);
        }
      })
      .finally(() => {
        isSubmitting.current = false;
      });
  }, [isLoading, addMessage, handleAPICall, conversation.length, updateUrl]);

  const fetchSuggestions = useCallback(async (text) => {
    if (!text.trim() || isSubmitting.current) {
      setSuggestions([]);
      return;
    }
    try {
      const data = await getSuggestions(text);
      if (!isSubmitting.current) {
        setSuggestions(data.reverse());
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setSuggestions([]);
    }
  }, []);

  const debouncedFetchSuggestions = useCallback(
    debounce(fetchSuggestions, 300),
    [fetchSuggestions]
  );

  const handleSelectWord = useCallback((word) => {
    setSelectedWord(word);
    setPrompt(word);
    setSuggestions([]);
  }, []);

  const reset = useCallback(() => {
    setConversation([]);
    setPrompt('');
    setIsLoading(false);
    setSuggestions([]);
    setSelectedWord('');
    setTitle('');
    clearUrlParam();
    inputRef.current?.focus();
  }, [clearUrlParam]);

  useEffect(() => {
    inputRef.current?.focus();

    if (!initialSearchDone.current) {
      const searchParams = new URLSearchParams(window.location.search);
      const word = searchParams.get('w');
      if (word) {
        const validatedWord = trimInput(word);
        if (validatedWord) {
          setPrompt(validatedWord);
          handleSubmit(validatedWord);
        } else {
          clearUrlParam();
        }
      }
      initialSearchDone.current = true;
    }
  }, [handleSubmit, clearUrlParam]);

  return {
    prompt,
    setPrompt,
    conversation,
    isLoading,
    inputRef,
    suggestions,
    selectedWord,
    handleSubmit,
    reset,
    handleSelectWord,
    debouncedFetchSuggestions,
    setSuggestions,
  };
}

export default useChatLogic;
