import React, { useRef, useEffect } from 'react';
import ChatMessage from './ChatMessage';

function ChatWindow({ conversation, isLoading }) {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [conversation]);

  return (
    <div className={`chat-window ${isLoading ? 'loading' : ''}`}>
      {conversation.map((message, index) => (
        <ChatMessage
          key={index}
          message={message}
          isFirst={index === 0}
        />
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
}

export default ChatWindow;
