import React from 'react';
import './styles/app.scss';
import './styles/auth.scss';
import ChatComponent from './components/Chat/ChatComponent';
import AuthWindow from './components/User/AuthWindow';

function App() {
  return (
    <div className="App">
      {/* <AuthWindow /> */}
      <ChatComponent />
    </div>
  );
}

export default App;


/**
 * Alternate version of App that uses react-router-dom
 */
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import AuthCallback from './components/AuthCallback';

// function App() {
//   return (
//     <Router>
//       <Switch>
//         {/* Other routes */}
//         <Route path="/auth/callback" component={AuthCallback} />
//       </Switch>
//     </Router>
//   );
// }

/**
 * AuthCallback is a component that handles the authentication callback after successful login
 */
// import React, { useEffect } from 'react';
// import { useLocation, useHistory } from 'react-router-dom';
// import { handleAuthCallback } from './userApi';

// function AuthCallback() {
//   const location = useLocation();
//   const history = useHistory();

//   useEffect(() => {
//     const urlParams = new URLSearchParams(location.search);
//     const code = urlParams.get('code');

//     if (code) {
//       handleAuthCallback(code)
//         .then(() => {
//           // Redirect to a protected route or dashboard
//           history.push('/dashboard');
//         })
//         .catch((error) => {
//           console.error('Authentication failed:', error);
//           // Redirect to login page or show an error message
//           history.push('/login');
//         });
//     }
//   }, [location, history]);

//   return <div>Completing authentication...</div>;
// }

// export default AuthCallback;