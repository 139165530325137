import React, { useEffect, useState } from 'react';

function Logo() {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAnimate(true);
    }, 750);
  }, []);

  const handleClick = () => {
    setAnimate(false);
    setTimeout(() => setAnimate(true), 10);
  };

  return (
    <div className="logo-container" onClick={handleClick}>
      <div className={`logo-image-container ${animate ? 'animate' : ''}`}>
        <div className="circle circle-tl"></div>
        <div className="circle circle-bl"></div>
        <div className="circle circle-tr"></div>
        <div className="circle circle-br"></div>
      </div>
    </div>
  );
}

export default Logo;
