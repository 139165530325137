import { Version } from './constants';

export const trimInput = (input) => {
  return input
    .toLowerCase()
    .trim()
    .replace(/[^a-z-]/g, '')
    .replace(/-{2,}/g, '-');
};

export const setTitle = (value) => {
  document.title = value ? `${value} - dictionarychat.ai` : 'Dictionary Chat';
};

export const getVersion = () => {
  return `${Version.MAJOR}.${Version.MINOR}.${Version.PATCH}`;
};
