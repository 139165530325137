import React from 'react';
import { getVersion } from '../../utils/utils';
import { X_URL } from "../../utils/constants";

function SystemInfo() {
  const currentYear = new Date().getFullYear();

  const renderVersion = () => {
    return (
      <a href={X_URL} target="_blank" rel="noopener noreferrer">
        <p><span className="version">v{getVersion()}</span></p>
      </a>
    );
  };

  const renderCopyright = () => {
    return <p><span className="copyright">©</span> {currentYear} Syntactic Sgr</p>;
  };

  return (
    <div className="system-info-container">
      <div className="system-info">
        {renderVersion()} {renderCopyright()}
      </div>
    </div>
  );
}

export default SystemInfo;
