import React from 'react';
import useChatLogic from '../../hooks/useChatLogic';
import ChatWindow from './ChatWindow';
import ChatInput from './ChatInput';
import Logo from '../Logo/Logo';
import ResetButton from '../ResetButton/ResetButton';
import WordSuggestion from '../WordSuggestion/WordSuggestion';
import SystemInfo from '../SystemInfo/SystemInfo';
import Background from '../App/Background';

import '../../styles/logo.scss';
import '../../styles/main.scss';
import '../../styles/input.scss';
import '../../styles/chat.scss';
import '../../styles/responsive.scss';
import '../../styles/markdown.scss';
import '../../styles/system.scss';
import '../../styles/suggestion.scss';
import '../../styles/background.scss';

function ChatComponent() {
  const {
    prompt,
    setPrompt,
    conversation,
    isLoading,
    inputRef,
    suggestions,
    selectedWord,
    handleSubmit,
    reset,
    handleSelectWord,
    debouncedFetchSuggestions,
    setSuggestions,
  } = useChatLogic();

  return (
    <>
      <Background />
      <div className="window-component">
        <Logo />
        {conversation.length > 0 && <ResetButton reset={reset} />}
        <div className={'chat-container' + (conversation.length === 0 ? ' empty' : ' animate')}>
          <ChatWindow conversation={conversation} isLoading={isLoading} />
          <div className="chat-input-body">
            <WordSuggestion
              suggestions={suggestions}
              onSelectWord={handleSelectWord}
            />
            <ChatInput
              prompt={prompt}
              setPrompt={setPrompt}
              handleSubmit={handleSubmit}
              isLoading={isLoading}
              isReply={conversation.length > 0}
              inputRef={inputRef}
              debouncedFetchSuggestions={debouncedFetchSuggestions}
              selectedWord={selectedWord}
              setSuggestions={setSuggestions}
            />
          </div>
        </div>
        <SystemInfo />
      </div>
    </>
  );
}

export default ChatComponent;
