import React, { useEffect, useState } from 'react';
import TypingIndicator from './TypingIndicator';
import { trimInput } from '../../utils/utils';

function ChatInput({
                     prompt,
                     setPrompt,
                     handleSubmit,
                     isLoading,
                     isReply,
                     inputRef,
                     debouncedFetchSuggestions,
                     selectedWord,
                     setSuggestions,
                   }) {
  const [inputClass, setInputClass] = useState('');
  const processPrompt = (input) => {
    return isReply ? input : trimInput(input);
  };

  const handleInputChange = (e) => {
    const newPrompt = processPrompt(e.target.value);
    setPrompt(newPrompt);

    if (!isReply) {
      if (newPrompt.length <= 2) {
        debouncedFetchSuggestions.cancel();
        setSuggestions([]);
      } else {
        debouncedFetchSuggestions(newPrompt);
      }
    }
  };

  const getInputClass = (length) => {
    const classes = ['', 'one', 'two', 'three'];
    return length >= classes.length ? 'max' : classes[length];
  };

  useEffect(() => {
    setInputClass(getInputClass(prompt.length));
  }, [prompt]);

  useEffect(() => {
    if (selectedWord) {
      handleSubmit(trimInput(selectedWord));
    }

    return () => {
      setSuggestions([]);
    };
  }, [selectedWord, handleSubmit, setSuggestions]);

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(prompt);

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className={`chat-input-container${isReply ? ' reply' : ''}`}>
      <form onSubmit={onSubmit} className="chat-form">
        <input
          ref={inputRef}
          type="text"
          value={prompt}
          onChange={handleInputChange}
          placeholder={isReply ? 'Enter a reply' : 'Enter a word'}
          className={`${inputClass} chat-input${isReply ? ' reply' : ''}`}
        />
        {(prompt || isLoading) && (
          <button type="submit" disabled={isLoading || !prompt.length}
                  className="chat-button">
            <p className={isLoading ? ' loading' : ''}>{isLoading ?
              'Generating' :
              'Send'}</p>
            {isLoading && <TypingIndicator/>}
          </button>
        )}
      </form>
    </div>
  );
}

export default ChatInput;
