import { API_URL } from './constants';

export async function callAPI(conversation, onChunk) {
  try {
    console.log('Sending conversation:', conversation);

    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'text/event-stream',
      },
      credentials: 'omit',
      body: JSON.stringify({ conversation }),
    });

    console.log('Response received:', {
      status: response.status,
      statusText: response.statusText,
      headers: Object.fromEntries(response.headers.entries()),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Error response:', errorText);

      try {
        const errorJson = JSON.parse(errorText);
        throw new Error(`Server error: ${errorJson.details || errorJson.error}`);
      } catch (e) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    }

    if (!response.body) {
      throw new Error('No readable stream available');
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    let buffer = '';
    let fullMessage = '';

    try {
      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          console.log('Stream complete');
          break;
        }

        const chunk = decoder.decode(value, { stream: true });
        console.log('Raw chunk received:', chunk);

        // If we receive a JSON error response instead of SSE
        if (chunk.includes('"error":')) {
          try {
            const errorResponse = JSON.parse(chunk);
            throw new Error(errorResponse.details || errorResponse.error);
          } catch (e) {
            console.error('Error parsing error response:', e);
          }
        }

        buffer += chunk;

        while (buffer.includes('\n')) {
          await new Promise(resolve => setTimeout(resolve, 5)); // 5ms delay

          const lineEnd = buffer.indexOf('\n');
          const line = buffer.slice(0, lineEnd).trim();
          buffer = buffer.slice(lineEnd + 1);

          if (line.startsWith('data: ')) {
            const data = line.slice(6);
            console.log('Processed line:', data);

            if (data === '[DONE]') {
              console.log('Received DONE signal');
              return fullMessage;
            }

            try {
              const parsed = JSON.parse(data);
              if (parsed.content) {
                fullMessage += parsed.content;
                onChunk(parsed.content);
              }
            } catch (e) {
              console.error('Error parsing line:', line, e);
            }
          }
        }
      }
    } catch (error) {
      console.error('Error reading stream:', error);
      throw error;
    } finally {
      reader.releaseLock();
    }

    return fullMessage;
  } catch (error) {
    console.error('API call failed:', error);
    throw error;
  }
}

export async function getSuggestions(text) {
  if (!text) return [];
  try {
    const response = await fetch(
      `https://api.datamuse.com/sug?s=${encodeURIComponent(text)}&max=5`);
    const data = await response.json();
    return data ? data : [];
  } catch (error) {
    console.error('Error fetching suggestions:', error);
    return [];
  }
}
