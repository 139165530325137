import React from 'react';
import ReactMarkdown from 'react-markdown';

function ChatMessage({ message, isFirst }) {
  const messageClass = message.role === 'user' ? 'message-user' : 'message-ai';
  return (
    <div className={`message ${messageClass}`}>
      <p className={`title ${isFirst ? 'first' : ''}`}>
        {message.role === 'user' ? <span /> : ''}
        {message.role === 'user' ? 'You' : ''}
      </p>
      <div className={message.role === 'user' ? '' : 'message-ai-content markdown-content'}>
        {message.role === 'user' ? (
          message.content
        ) : (
          <ReactMarkdown>{message.content}</ReactMarkdown>
        )}
      </div>
    </div>
  );
}

export default ChatMessage;
