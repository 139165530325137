export const API_URL = 'https://04t6cahq8e.execute-api.us-east-1.amazonaws.com/prod/'; // prod (us-east-1)
// export const API_URL = 'https://eja3wcx74d.execute-api.us-west-2.amazonaws.com/prod/'; // beta (us-west-2)

export const X_URL = 'https://x.com/dictionarychat';

export const Version = {
  MAJOR: 1,
  MINOR: 2,
  PATCH: 16,
};



